import { Box, Typography, useTheme } from '@mui/material';
import { checkIfValidElement } from '../../../utils/checkIfValidElement';

const LabelTextForHtml = ({
  headerKey,
  currentData,
  upcommingData,
  dataPresentationStatus,
  customeCurrentComponent,
  customeUpcommingComponent,
  customePreviousComponent,
}: any) => {
  const { palette } = useTheme();

  const upcomingData = checkIfValidElement(upcommingData);
  const curentData = checkIfValidElement(currentData);

  // UPCOMING
  const upComming =
    upcomingData !== curentData &&
    upcommingData !== undefined &&
    headerKey !== 'Effective Date' &&
    headerKey !== 'Join Date' &&
    headerKey !== 'Subordinates' &&
    headerKey !== 'Direct Reports' &&
    // fix status capsule to disappearad on upcoming changes
    // headerKey !== 'Status' &&
    headerKey !== 'Birth Date' &&
    headerKey !== 'Status' &&
    headerKey !== 'Calendar' ? (
      <>
        {customeCurrentComponent || currentData}{' '}
        <Typography variant="body14" color={palette.green[60]} component="span">
          → {customeUpcommingComponent || upcommingData}
        </Typography>
      </>
    ) : (
      customeCurrentComponent || currentData || upcommingData
    );

  // // PREVIOUS
  const previous =
    upcomingData !== curentData ? (
      <Typography variant="body14" color={palette.red[60]} component="span">
        {customePreviousComponent || currentData}
      </Typography>
    ) : (
      customePreviousComponent || currentData
    );

  if (dataPresentationStatus === 'PREVIOUS') {
    return (
      <Box display="flex" flexWrap="wrap">
        <Typography flex="1 1 0" variant="body14" color={palette.gray[80]}>
          {headerKey}
        </Typography>
        <Typography
          flex="2 1 0"
          variant="body14"
          color={palette.gray[100]}
          sx={{
            wordWrap: 'break-word',
            maxWidth: '400px',
          }}
        >
          {previous}
        </Typography>
      </Box>
    );
  }

  // return upcoming
  if (dataPresentationStatus === 'UPCOMING') {
    return (
      <Box display="flex" flexWrap="wrap">
        <Typography flex="1 1 0" variant="body14" color={palette.gray[80]}>
          {headerKey}
        </Typography>
        <Typography
          flex="2 1 0"
          variant="body14"
          color={palette.gray[100]}
          sx={{
            wordWrap: 'break-word',
            maxWidth: '400px',
          }}
        >
          {upComming}
        </Typography>
      </Box>
    );
  }

  return (
    <Box display="flex" flexWrap="wrap">
      <Typography flex="1 1 0" variant="body14" color={palette.gray[80]}>
        {headerKey}
      </Typography>
      <Typography
        flex="2 1 0"
        variant="body14"
        color={palette.gray[100]}
        sx={{
          wordWrap: 'break-word',
          maxWidth: '400px',
        }}
      >
        {customeCurrentComponent || currentData}
      </Typography>
    </Box>
  );
};

export default LabelTextForHtml;
