import { createSlice } from '@reduxjs/toolkit';
import { AccumulatorPayItem } from '../../@types/AccumulatorTypes';
import { userAccessApiSlice } from './service/userAccessApiSlice';

interface initialStateI {
  messageSuccess: string;
  messageError: string;
  payItems: Array<AccumulatorPayItem>;
}

const initialState: initialStateI = {
  messageSuccess: '',
  messageError: '',
  payItems: [],
};

const userAccessSlice = createSlice({
  name: 'userAccess',
  initialState,
  reducers: {
    removeMessage: state => {
      state.messageSuccess = '';
      state.messageError = '';
    },
  },
  extraReducers(builder) {
    builder.addMatcher(
      userAccessApiSlice.endpoints.createUserAccess.matchFulfilled,
      (state, _action) => {
        state.messageSuccess = 'User access created';
      }
    );
  },
});

export const { removeMessage } = userAccessSlice.actions;
export const userAccessReducer = userAccessSlice.reducer;
