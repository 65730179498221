import { lazy } from 'react';

const List = lazy(() => import('../../../pages/timeEvent/List'));
const Create = lazy(() => import('../../../pages/timeEvent/Create'));
const Details = lazy(() => import('../../../pages/timeEvent/Details'));
const Update = lazy(() => import('../../../pages/timeEvent/Update'));

export const timeEvent = [
  {
    label: 'Time Event',
    child: null,
    path: 'time-event',
    component: List,
  },
  {
    label: '',
    child: null,
    path: 'time-event/create',
    component: Create,
  },
  {
    label: '',
    path: 'time-event/detail/:id',
    component: Details,
    child: null,
  },
  {
    label: '',
    path: 'time-event/update/:id',
    component: Update,
    child: null,
  },
];
