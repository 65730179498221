import { createSlice } from '@reduxjs/toolkit';
import { AccessControlApiSlice } from './service/accessControl';

interface initialStateI {
  messageSuccess: string;
  messageError: string;
}

const initialState: initialStateI = {
  messageSuccess: '',
  messageError: '',
};

const AccessControlSlice = createSlice({
  name: 'accessControl',
  initialState,
  reducers: {
    removeMessage: state => {
      state.messageSuccess = '';
      state.messageError = '';
    },
  },
  extraReducers(builder) {
    builder.addMatcher(
      AccessControlApiSlice.endpoints.createAccessControl.matchFulfilled,
      state => {
        state.messageSuccess = 'Access Control created';
      }
    );
    builder.addMatcher(
      AccessControlApiSlice.endpoints.updateAccessControl.matchFulfilled,
      state => {
        state.messageSuccess = 'Access Control updated';
      }
    );
    builder.addMatcher(
      AccessControlApiSlice.endpoints.deleteAccessControl.matchFulfilled,
      state => {
        state.messageSuccess = 'Access Control deleted';
      }
    );
  },
});

export const { removeMessage } = AccessControlSlice.actions;
export const AccessControllReducer = AccessControlSlice.reducer;
