import { QueryParams } from '../../../@types/QueryParams';
import { apiSlice } from '../api/apiSlice';
import { ListResponse } from '../../../@types/ListResponseProps';
import { UserAccessType } from '../../../@types/UserAccessType';

type RequestParameterType = {
  id: string;
  effectiveDate?: string;
  sortType?: string;
  sortBy?: string;
  search?: string;
};

export const userAccessApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getUserAccessList: builder.query<ListResponse<UserAccessType>, QueryParams>(
      {
        query: params => {
          const { search, page, sortType, sort, searchString } = params;
          return {
            url: `/security/user-access`,
            params: { search, page, sortType, sort, ...searchString },
          };
        },
        providesTags: ['UserAccessList'],
      }
    ),
    getUserAccessData: builder.query<any, any>({
      query: params => ({
        url: '/security/user-access-data',
        params,
      }),
    }),
    getUserAccessFunction: builder.query<any, any>({
      query: params => {
        const { search } = params;
        return {
          url: '/security/user-access-functions',
          params: search,
        };
      },
    }),
    createUserAccess: builder.mutation<void, any>({
      query: body => ({
        url: `/security/user-access`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['UserAccessList'],
    }),
    getUserAccessHeaderDetail: builder.query<
      UserAccessType,
      RequestParameterType
    >({
      query: params => {
        const { id, effectiveDate } = params;
        return {
          url: `/payroll/accumulators/${id}`,
          params: { effectiveDate },
        };
      },
      providesTags: ['AccumulatorList', 'AccumulatorDetail'],
    }),
  }),
});

export const {
  useGetUserAccessDataQuery,
  useGetUserAccessFunctionQuery,
  useGetUserAccessListQuery,
  useGetUserAccessHeaderDetailQuery,
  useCreateUserAccessMutation,
} = userAccessApiSlice;
