import { Box, SxProps, useTheme } from '@mui/material';
import { ReactNode } from 'react';

import { DataType } from '../../../../@types/DataType';
import { ActionMenuProps } from '../../../../@types/TableProps';
import { Button, PreviousNextDateButton, Tags } from '../../../atom';
import {
  Check,
  Code02,
  Edit,
  Edit03,
  Trash,
  Variable,
  FileX02,
} from '../../../atom/icon';
import { LastUpdated, Title } from '../../../molecule';
import ActionButton from '../../../molecule/action/Action';
import Breadcrumb from '../../../molecule/breadcrumb/Breadcrumb';
import ContentContainer from '../content/ContentContainer';
import AlertBanner from '../../../atom/banner/AlertBanner';
import { calculateActiveIn } from '../../../../utils/date';

interface ContainerDetailProps {
  children: ReactNode;
  title?: ReactNode | string;
  data?: DataType;
  setSelectedDate?: Function;
  showHistoricalDate?: boolean;
  showLastUpdate?: boolean;
  onClickEdit?: Function;
  onClickMenuAction?: Function;
  showDateButton?: boolean;
  showCorrection?: boolean;
  showDelete?: boolean;
  showSwitchView?: boolean;
  formulaType?: string;
  viewType?: string;
  showWithDraw?: boolean;
  showAlertBanner?: boolean;
  onClickBreadcrumb?: any;
  customActionMenu?: any;
  showActiveIn?: Boolean;
  showActionButton?: Boolean;
  titleBreadCrumb?: string;
}

const ContainerDetails = ({
  children,
  title = '',
  data,
  setSelectedDate,
  showHistoricalDate,
  onClickEdit,
  showLastUpdate,
  onClickMenuAction,
  showDateButton = true,
  showCorrection = true,
  showDelete = true,
  showSwitchView = false,
  formulaType,
  viewType,
  showWithDraw = false,
  showAlertBanner = false,
  onClickBreadcrumb,
  customActionMenu,
  showActiveIn = false,
  showActionButton = true,
  titleBreadCrumb,
  ...props
}: ContainerDetailProps) => {
  const { palette } = useTheme();
  const style: SxProps = {
    gap: '0px',
    '& .title': {
      fontWeight: 700,
    },
    '& .container-title': {
      mb: '1rem',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    '& .breadcrumb': {
      mb: '2rem',
    },
    '& .subtitle': {
      mt: '0.5rem',
      fontWeight: 400,
      display: 'flex',
      alignItems: 'center',
      gap: '0.5rem',
    },
    '& .date': {
      fontWeight: 700,
    },
    '& .action-container': {
      display: 'flex',
      gap: '1rem',
      alignItems: 'center',
    },
  };
  /* eslint no-nested-ternary: "off" */
  const ActionMenu: ActionMenuProps[] = [
    ...(showSwitchView
      ? [
          {
            name: 'switchView',
            title: `Switch to ${
              viewType === 'Variable' ? 'Code' : 'Variable'
            } view`,
            Icon: viewType === 'Variable' ? <Code02 /> : <Variable />,
            hasBorderBottom: true,
          },
        ]
      : []),
    {
      name: 'update',
      title: 'Update',
      Icon: <Edit />,
    },
    ...(showCorrection
      ? [
          {
            name: 'correction',
            title: 'Correction',
            Icon: <Check />,
          },
        ]
      : []),
    ...(showWithDraw
      ? [
          {
            name: 'withdraw',
            title: 'Withdraw',
            hasBorderTop: true,
            Icon: <FileX02 />,
          },
        ]
      : []),
    ...(showDelete && formulaType !== 'DEFAULT'
      ? [
          {
            name: 'delete',
            title: 'Delete',
            Icon: <Trash />,
            hasBorderTop: true,
            color: palette.red[50],
          },
        ]
      : []),
  ];
  return (
    <ContentContainer sx={style} {...props}>
      <Breadcrumb
        className="breadcrumb"
        onClickBack={onClickBreadcrumb}
        titleBreadCrumb={titleBreadCrumb}
      />
      {showAlertBanner && <AlertBanner />}
      {title && (
        <Box className="container-title">
          <Box
            sx={{ display: 'flex', justifyContent: 'space-between', flex: 1 }}
          >
            <Box sx={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
              <Title title={title} />
              {showActiveIn && (
                <Tags
                  label={calculateActiveIn(data?.effectiveDate)}
                  variant="gray"
                />
              )}
            </Box>
            {showHistoricalDate && showLastUpdate && (
              <LastUpdated lastUpdated={data?.effectiveDate} />
            )}
            {onClickEdit && (
              <Button
                variant="outlined"
                sx={{
                  color: palette.gray[100],
                  '& svg': {
                    transform: 'scale(0.8333)',
                    '& path': {
                      strokeWidth: 2,
                      stroke: palette.gray[100],
                    },
                  },
                }}
                onClick={() => onClickEdit?.()}
              >
                <Edit03 /> Update
              </Button>
            )}
          </Box>
          {showHistoricalDate && (
            <Box className="action-container">
              {showDateButton && (
                <PreviousNextDateButton
                  headersState={{
                    previousEffectiveDate: data?.previousEffectiveDate || null,
                    upcomingEffectiveDate: data?.upcomingEffectiveDate || null,
                    effectiveDate: data?.effectiveDate || null,
                    dataPresentationStatus: data?.dataPresentationStatus || '',
                  }}
                  handleClickPrevious={() =>
                    setSelectedDate?.(data?.previousEffectiveDate || '')
                  }
                  handleClickUpcoming={() =>
                    setSelectedDate?.(data?.upcomingEffectiveDate || '')
                  }
                />
              )}

              {showActionButton ? (
                <ActionButton
                  actions={customActionMenu || ActionMenu}
                  actionPopoverDirection="right"
                  disabled={!data?.isEnableUpdated}
                  onClickMenuAction={(e: string) => {
                    onClickMenuAction?.(e);
                  }}
                />
              ) : null}
            </Box>
          )}
        </Box>
      )}
      {children}
    </ContentContainer>
  );
};

export default ContainerDetails;
