import { Box, Grid, Typography, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import TaskItem from '../../../molecule/taskItem/TaskItem';
import { ChevronDown, Clock } from '../../../atom/icon';
import ApprovalLeave from '../../modal/ApprovalLeave/ApprovalLeave';
import { TaskOverviewType } from '../../../../@types/HomeTypes';
import {
  useGetListTaskQuery,
  useGetListAttendanceRequestQuery,
} from '../../../../store/feature/service/homeApiSlice';
import { Button } from '../../../atom';
import {
  formatUnixToString,
  getCurrentTime,
  newformatDateTimeFromUnixUTC,
} from '../../../../utils/date';
import { useAppSelector } from '../../../../store';
import Toast from '../../../atom/toast/Toast';
import { removeMessage } from '../../../../store/feature/homeSlice';
import AttendanceRequestItem from '../../../molecule/attendanceRequestItem/AttendanceRequestItem';
import TaskOverviewLoading from '../../taskOverview/TaskOverviewLoading';

const PendingApproval = () => {
  const { palette } = useTheme();
  const dispatch = useDispatch();
  const [limit, setLimit] = useState(3);
  const [openModal, setOpenModal] = useState<
    'TIME_OFF' | 'ATTENDANCE_REQUEST' | 'DATA_UPDATE' | null
  >(null);
  const [concattedData, setConcattedData] = useState<Array<any>>([]);
  const [activeData, setActiveData] = useState<TaskOverviewType | null>(null);
  const { messageError, messageSuccess } = useAppSelector(state => state.home);
  const { data, isLoading } = useGetListTaskQuery({
    page: 1,
    limit,
    sortType: 'desc',
    sortBy: 'createdAt',
    search: '',
    searchString: {},
  });
  const { data: listAttendanceRequest, isLoading: loading } =
    useGetListAttendanceRequestQuery({
      page: 1,
      limit,
      sortType: 'desc',
      sortBy: 'createdAt',
      search: '',
      searchString: {},
    });

  const onClickTaskItem = (value: TaskOverviewType) => {
    setActiveData(value);

    if (value.timeOffRequestId) {
      return setOpenModal('TIME_OFF');
    }
    return setOpenModal('ATTENDANCE_REQUEST');
  };

  const handleCloseToast = () => {
    dispatch(removeMessage());
  };

  useEffect(() => {
    if (data && listAttendanceRequest) {
      if (limit === 3) {
        setConcattedData(_prev =>
          [...data.data, ...listAttendanceRequest.data]
            .sort((a, b) => a.createdAt - b.createdAt)
            .slice(0, 3)
        );
      } else {
        setConcattedData(_prev =>
          [...data.data, ...listAttendanceRequest.data].sort(
            (a, b) => a.createdAt - b.createdAt
          )
        );
      }
    }
  }, [data, listAttendanceRequest]);

  const renderDescription = (e: any) => {
    if (e.attendanceRequestId)
      return `(${e.employeeNumber}) ${
        e.employeeFullName
      } has requested attendance on ${newformatDateTimeFromUnixUTC(
        e?.date
      )} for  ${e?.shiftCode}  (${getCurrentTime(
        e?.clockIn
      )} - ${getCurrentTime(e?.clockOut)}) `;
    if (e.timeOffRequestId) {
      return `(${e.employeeNumber}) ${e.employeeFullName} has requested ${e.typeName} for ${e.totalDay} day(s).`;
    }

    return ` ${
      e.fullName
    } has requested you to ${e?.actionType?.label?.toLowerCase()} the ${
      e?.module?.label
    } data.`;
  };

  const determineListTitle = (val: any) => {
    if (val.timeOffRequestId) {
      return 'Time Off Request';
    }
    if (val.attendanceRequestId) {
      return 'Attendance Request';
    }
    return 'Data Update Request';
  };

  return (
    <Grid container direction="column" gap="16px">
      <Toast
        message={messageError}
        variant="error"
        open={messageError !== ''}
        onClose={handleCloseToast}
      />
      <Toast
        message={messageSuccess}
        variant="success"
        open={messageSuccess !== ''}
        onClose={handleCloseToast}
      />
      {openModal === 'TIME_OFF' && (
        <ApprovalLeave
          data={activeData}
          handleClose={() => setOpenModal(null)}
        />
      )}
      {openModal === 'ATTENDANCE_REQUEST' && (
        <AttendanceRequestItem
          data={activeData}
          handleClose={() => setOpenModal(null)}
        />
      )}

      {!isLoading && (
        <Grid item>
          <Typography fontSize="18px" fontWeight={700} color="#101B28">
            Task ({concattedData?.length})
          </Typography>
        </Grid>
      )}
      {isLoading && <TaskOverviewLoading />}
      {concattedData?.length === 0 && (
        <Typography variant="body14" color={palette.gray[100]}>
          No tasks available
        </Typography>
      )}
      {concattedData?.map(e => (
        <TaskItem
          key={e.id}
          icon={
            <Box
              sx={{
                '& svg': {
                  width: '20px',
                  height: '20px',
                  display: 'flex',
                  '& path': {
                    stroke: palette.gray[80],
                    strokeWidth: 1.5,
                  },
                },
              }}
            >
              <Clock />
            </Box>
          }
          title={determineListTitle(e)}
          onClick={() => {
            onClickTaskItem(e);
          }}
          image={
            <img
              src={e.profileURL || e.profileUrl}
              alt="employee profile"
              style={{
                width: '40px',
                height: '45px',
                objectFit: 'cover',
              }}
            />
          }
          description={renderDescription(e)}
          caption={formatUnixToString(e.createdAt)}
        />
      ))}
      {data !== undefined && data.total !== data.data.length && (
        <Grid item container justifyContent="center">
          <Button
            variant="link"
            onClick={() => {
              setLimit(9999999);
            }}
          >
            <ChevronDown /> View All <ChevronDown />
          </Button>
        </Grid>
      )}
    </Grid>
  );
};

export default PendingApproval;
