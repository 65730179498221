export default function rendertags(type: string) {
  const formatted = type.includes('_')
    ? type.toLowerCase().split('_').join()
    : type.toLowerCase();
  if (formatted === 'rejected') {
    return 'error';
  }
  if (
    formatted === 'approved' ||
    formatted === 'requested' ||
    formatted === 'received'
  ) {
    return 'success';
  }
  return 'warning';
}
