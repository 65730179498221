import { createSlice } from '@reduxjs/toolkit';
import { newsApiSlice } from './service/newsApiSlice';
import { homeApiSlice } from './service/homeApiSlice';

interface initialStateI {
  messageSuccess: string;
  messageError: string;
}

const initialState: initialStateI = {
  messageSuccess: '',
  messageError: '',
};

const newsSlice = createSlice({
  name: 'news',
  initialState,
  reducers: {
    removeMessage: state => {
      state.messageSuccess = '';
      state.messageError = '';
    },
  },
  extraReducers(builder) {
    builder.addMatcher(
      newsApiSlice.endpoints.createNews.matchFulfilled,
      (state, _action) => {
        state.messageSuccess = 'News created';
      }
    );
    builder.addMatcher(
      newsApiSlice.endpoints.createNews.matchRejected,
      (state, action) => {
        const messageError = action.payload as any;
        state.messageError = messageError.data?.message || 'Failed create news';
      }
    );
    builder.addMatcher(
      newsApiSlice.endpoints.updateNews.matchFulfilled,
      (state, _action) => {
        state.messageSuccess = 'News updated';
      }
    );
    builder.addMatcher(
      newsApiSlice.endpoints.updateNews.matchRejected,
      (state, action) => {
        const messageError = action.payload as any;
        state.messageError =
          messageError?.data?.errors?.effectiveDate?.[0] === 'DUPLICATE'
            ? 'The new publish date must not be the same as the existing publish date.'
            : messageError.data?.message || 'Failed update news';
      }
    );
    builder.addMatcher(
      newsApiSlice.endpoints.cancelUpcomingNews.matchFulfilled,
      (state, _action) => {
        state.messageSuccess = 'Upcoming Changes cancelled';
      }
    );
    //
    builder.addMatcher(
      homeApiSlice.endpoints.approvalTaskAttendanceRequest.matchFulfilled,
      (state, action) => {
        action.payload.statusCode === 'APPROVED'
          ? (state.messageSuccess = 'Attendance Request approved')
          : (state.messageSuccess = 'Attendance Request rejected');
      }
    );
    builder.addMatcher(
      homeApiSlice.endpoints.approvalTaskAttendanceRequest.matchRejected,
      (state, action) => {
        console.log(action);
        if (action.payload.status === 422) {
          state.messageError =
            'Another approver has taken action on this Attendance Request';
        }
      }
    );
  },
});

export const { removeMessage } = newsSlice.actions;
export const newsReducer = newsSlice.reducer;
