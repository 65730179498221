import { useState } from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { ESSContainer } from '../../components/template';
import TableView from '../../components/organism/tableView/TableView';
import { useGetListShiftQuery } from '../../store/feature/service/shiftApiSlice';
import { useAppSelector, useAppDispatch } from '../../store';
import { tableListHeaderKey } from '../../constants/shift';
import { objectMapper } from '../../utils/objectMap';
import {
  formatSecondsToHour,
  formatSecondsToTimeString,
} from '../../utils/date';
import Toast from '../../components/atom/toast/Toast';
import { removeMessage } from '../../store/feature/shiftSlice';
import { useDocumentTitle } from '../../hooks/useDocumentTitle';
import Breadcrumb from '../../components/molecule/breadcrumb/Breadcrumb';

const ListApprovedTimeOff = () => {
  useDocumentTitle('Approved Time Off | TricorUnify');
  const navigate = useNavigate();
  const { palette } = useTheme();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { pages, search, searchString } = useAppSelector(state => state.utils);
  const { messageSuccess } = useAppSelector(state => state.shift);
  const [sort, setSort] = useState({
    sortBy: '',
    sortType: '',
  });

  const { data: dataRow, isLoading } = useGetListShiftQuery({
    page: pages[location.pathname] || 1,
    limit: 10,
    sortBy: sort.sortBy,
    sortType: sort.sortType,
    search: search[location.pathname] || '',
    searchString: searchString[location.pathname],
  });

  const handleCLose = () => {
    dispatch(removeMessage());
  };

  return (
    <ESSContainer>
      <Toast
        message={messageSuccess}
        open={messageSuccess !== ''}
        variant="success"
        onClose={handleCLose}
      />
      <Breadcrumb className="breadcrumb" />
      <Typography variant="h4" color={palette.gray[100]} fontWeight="700">
        Approved Time Off
      </Typography>
      <TableView
        activeDescription
        headerKey={tableListHeaderKey}
        sortBy={sort.sortBy}
        sortType={sort.sortType}
        setSort={setSort}
        showSort
        showCreate={false}
        showEdit={false}
        stickyLeft
        showFilter={false}
        rowData={
          dataRow?.data.map((data: any) => ({
            ...objectMapper(data),
            colorIndicator: (
              <Box sx={{ display: 'flex', gap: '8px' }}>
                <Box
                  sx={{
                    width: '20px',
                    height: '20px',
                    bgcolor: data.colorIndicator,
                    borderRadius: '4px',
                  }}
                />
                {data?.colorIndicator?.split('#')[1]}
              </Box>
            ),
            startTime: formatSecondsToHour(data.startTime),
            endTime: formatSecondsToHour(data.endTime),
            totalWorkHours: data?.totalWorkHours
              ? formatSecondsToTimeString(data.totalWorkHours)
              : '-',
          })) || []
        }
        pageSize={Math.ceil((dataRow?.total || 0) / 10)}
        totalData={dataRow?.total || 0}
        onDescriptionClick={(id: string) =>
          navigate(`/ess/employee/approved-time-off/detail/${id}`)
        }
        showPagination
        showAction={false}
        isLoading={isLoading}
      />
    </ESSContainer>
  );
};

export default ListApprovedTimeOff;
