import { CheckboxTreeLabel } from '../@types/CheckboxType';
import { UserAccessCreateMenu } from '../@types/UserAccessType';

const headerKey = {
  type: 'User Type',
  name: 'Name',
  email: 'Email',
  lastLogin: 'Last Login',
  accessControl: 'Access Control',
};

const locations = {
  list: '/security/user-access',
  detail: '/security/user-access/detail',
  create: '/security/user-access/create',
  internal: '/security/user-access/create/internal',
  external: '/security/user-access/create/external',
  update: '/security/user-access/update',
};

const options = {
  userType: [
    {
      label: 'Internal',
      value: 'Internal',
    },
    {
      label: 'External',
      value: 'External',
    },
  ],
};

const filterFields = [
  {
    name: 'userType',
    label: 'User Type',
    type: 'select',
    options: options.userType,
    isMulti: true,
  },
  {
    name: 'accessControl',
    label: 'Access Control',
    type: 'selectPaginate',
    url: '',
    isMulti: true,
  },
];

const defaultFilterValue = {
  userType: null,
  accessControl: null,
};

const detailKey: { [key: string]: string } = {
  // effectiveDate: 'Effective Date',
  userType: 'User Type',
  name: 'Name',
  email: 'Email',
  lastLogin: 'Last Login',
  accessControl: 'Access Control',
};

const stepperCreateUserAccess = [
  'Setup',
  'Specific Data Access (Optional)',
  'Specific Function Access (Optional)',
] as UserAccessCreateMenu[];

const listVariant = [
  'Setup',
  'Specific Data Access (Optional)',
  'Specific Function Access (Optional)',
] as UserAccessCreateMenu[];

const stepperStep = {
  setup: 0,
  dataAccess: 1,
  functionAccess: 2,
};

const dataAccessLabel: CheckboxTreeLabel[] = [
  {
    id: 'legalEE',
    label: 'Legal Entity',
    name: 'legalEntity',
    isChecked: false,
    isExpanded: true,
    children: [
      {
        id: 'ori-indo',
        label: 'Tricor Orisoft Indonesia',
        name: 'TRICOR_ORISFOT_INDONESIA',
        isChecked: false,
      },
      {
        id: 'ori-malay',
        label: 'Tricor Orisoft Malaysia',
        name: 'TRICOR_ORISFOT_MALAYSIA',
        isChecked: false,
      },
      {
        id: 'ori-tai',
        label: 'Tricor Orisoft Thailand',
        name: 'TRICOR_ORISFOT_THAILAND',
        isChecked: false,
      },
    ],
  },
  {
    id: 'org-101',
    label: 'Organization',
    name: 'organization',
    isChecked: false,
    isExpanded: true,
    children: [
      {
        id: 'pr-101',
        label: 'Product',
        name: 'PRODUCT',
        isChecked: false,
      },
      {
        id: 'tech-101',
        label: 'Technology',
        name: 'TECHNOLOGY',
        isChecked: false,
      },
      {
        id: 'hr-101',
        label: 'Human Resource',
        name: 'HR',
        isChecked: false,
        isExpanded: true,
      },
      {
        id: 'fa-offi',
        label: 'Finance',
        name: 'FINANCE',
        isChecked: false,
      },
    ],
  },
  {
    id: 'job-position',
    label: 'Job Position',
    name: 'JOB_POSITION',
    isChecked: false,
    isExpanded: true,
    children: [
      {
        id: 'pm-101',
        label: 'Product Manager',
        name: 'PRODUCT_MANAGER',
        isChecked: false,
      },
      {
        id: 'product-design-101',
        label: 'Product Designer',
        name: 'PRODUCT_DESIGNER',
        isChecked: false,
      },
      {
        id: 'sales-101',
        label: 'Sales',
        name: 'SALES',
        isChecked: false,
      },
      {
        id: 'marketing-1091',
        label: 'Marketing',
        name: 'MARKETING',
        isChecked: false,
      },
      {
        id: 'business-outsourcing-1091',
        label: 'Business Process Outsourcing',
        name: 'OUTSOURCING',
        isChecked: false,
      },
    ],
  },
  {
    id: 'job-grade-101',
    label: 'Job Grade',
    name: 'JOB_GRADE',
    isChecked: false,
    isExpanded: true,
    children: [
      {
        id: 'grade-01',
        label: 'Grade 1',
        name: 'GRADE_1',
        isChecked: false,
      },
      {
        id: 'grade-02',
        label: 'Grade 2',
        name: 'GRADE_2',
        isChecked: false,
      },
      {
        id: 'grade-03',
        label: 'Grade 3',
        name: 'GRADE_3',
        isChecked: false,
      },
      {
        id: 'grade-04',
        label: 'Grade 4',
        name: 'GRADE_4',
        isChecked: false,
      },
      {
        id: 'grade-05',
        label: 'Grade 5',
        name: 'GRADE_5',
        isChecked: false,
      },
    ],
  },
];

const functionAccessLabel: Array<CheckboxTreeLabel> = [
  {
    id: 'dashboard-01',
    label: 'Dashboard',
    name: 'DASHBOARD',
    isChecked: false,
    isExpanded: true,
    children: [
      {
        id: 'demographic-0191',
        label: 'Demographic',
        name: 'DEMOGRAPHIC',
        isChecked: false,
        isExpanded: true,
        children: [
          {
            id: 'gender-01',
            label: 'Gender',
            name: 'GENDER',
            isChecked: false,
          },
          {
            id: 'age-01',
            label: 'Age',
            name: 'AGE',
            isChecked: false,
          },
          {
            id: 'generation-01',
            label: 'Generation',
            name: 'GENERATION',
            isChecked: false,
          },
          {
            id: 'tenure-01',
            label: 'Tenure',
            name: 'TENURE',
            isChecked: false,
          },
          {
            id: 'education-lavel-01',
            label: 'Education Level',
            name: 'EDUCATION_LEVEL',
            isChecked: false,
          },
        ],
      },
      {
        id: 'talent-acquisition-01',
        label: 'Talent Acquisition',
        name: 'TALENT_ACQUISITION',
        isChecked: false,
        isExpanded: true,
        children: [
          {
            id: 'num-of-hires-1',
            label: 'Number of Hires',
            name: 'NUMBER_OF_HIRES',
            isChecked: false,
          },
          {
            id: 'num-of-resignation-1',
            label: 'Number of Resignation',
            name: 'NUMBER_OF_RESIGNATION',
            isChecked: false,
          },
        ],
      },
      {
        id: 'payroll-10',
        label: 'Payroll',
        name: 'PAYROLL',
        isChecked: false,
        isExpanded: true,
        children: [
          {
            id: 'no-of-pay-emp-01',
            label: 'No. of Payroll Employee',
            name: 'NO_OF_PAYROLL_EMPLOYEE',
            isChecked: false,
          },
          {
            id: 'payroll-expense-01',
            label: 'Payroll Expense',
            name: 'PAYROLL_EXPENSE',
            isChecked: false,
          },
          {
            id: 'overtime-cost-01',
            label: 'Overtime Cost',
            name: 'OVERTIME_COST',
            isChecked: false,
          },
        ],
      },
      {
        id: 'time-management-111',
        label: 'Time Management',
        name: 'TIME_MANAGEMENT',
        isChecked: false,
        isExpanded: true,
        children: [
          {
            id: 'attendance-rate-01',
            label: 'Attendance Rate of Employee',
            name: 'ATTENDANCE_RATE_OF_EMPLOYEE',
            isChecked: false,
          },
          {
            id: 'absence-rate-01',
            label: 'Absence Rate of Employee',
            name: 'ABSENCE_RATE_OF_EMPLOYEE',
            isChecked: false,
          },
          {
            id: 'leave-rate-01',
            label: 'Leave Rate of Employee',
            name: 'LEAVE_RATE',
            isChecked: false,
          },
          {
            id: 'late-rate-01',
            label: 'Late Rate of Employee',
            name: 'LATE_RATE',
            isChecked: false,
          },
        ],
      },
    ],
  },
  {
    id: 'emp-101',
    label: 'Employee',
    name: 'EMPLOYEE',
    isChecked: false,
    isExpanded: true,
    children: [
      {
        id: 'emp-view-data-table-01',
        label: 'View Data Only (Table List)',
        name: 'VIEW_DATA_ONLY',
        isChecked: false,
      },
      {
        id: 'emp-export-01',
        label: 'Export Employee Data',
        name: 'EXPORT_EMPLOYEE_DATA',
        isChecked: false,
      },
      {
        id: 'emp-import-01',
        label: 'Import Employee Data',
        name: 'IMPORT_EMPLOYEE_DATA',
        isChecked: false,
      },
      {
        id: 'emp-mass-update-01',
        label: 'Mass Update',
        name: 'MASS_UPDATE',
        isChecked: false,
      },
      {
        id: 'emp-mass-correction-01',
        label: 'Mass Correction',
        name: 'MASS_CORRECTION',
        isChecked: false,
      },
      {
        id: 'emp-mass-resign-01',
        label: 'Mass Resign',
        name: 'MASS_RESIGN',
        isChecked: false,
      },
      {
        id: 'emp-create-01',
        label: 'Create New Employee',
        name: 'CREATE',
        isChecked: false,
      },
      {
        id: 'emp-view-drafts-01',
        label: 'View Drafts',
        name: 'VIEW_DRAFTS',
        isChecked: false,
      },
      {
        id: 'emp-view-details-01',
        label: 'View Employee Details',
        name: 'VIEW_EMPLOYEE_DETAILS',
        isChecked: false,
        isExpanded: true,
        children: [
          {
            id: 'emp-view-tab-1',
            label: 'View Employee Tab',
            name: 'VIEW_EMPLOYEE_TAB',
            isChecked: false,
            isExpanded: true,
            children: [
              {
                id: 'emp-view-personal-tab-01',
                label: 'View Personal Tab',
                name: 'VIEW_PERSONAL_TAB',
                isChecked: false,
                isExpanded: true,
                children: [
                  {
                    id: 'emp-view-personal-section-01',
                    label: 'View Personal Section',
                    name: 'EMPLOYEE_VIEW_PERSONAL_SECTION',
                    isChecked: false,
                    isExpanded: true,
                    children: [
                      {
                        id: 'personal-section-update-101',
                        label: 'Update',
                        name: 'UPDATE',
                        isChecked: false,
                      },
                      {
                        id: 'personal-section-correction-101',
                        label: 'Correction',
                        name: 'CORRECTION',
                        isChecked: false,
                      },
                      {
                        id: 'personal-section-delete-101',
                        label: 'Delete',
                        name: 'DELETE',
                        isChecked: false,
                      },
                    ],
                  },
                  {
                    id: 'emp-view-address-section-05',
                    label: 'View Address Section',
                    name: 'EMPLOYEE_VIEW_ADDRESS_SECTION',
                    isChecked: false,
                    isExpanded: true,
                    children: [
                      {
                        id: 'address-add-501',
                        label: 'Add',
                        name: 'ADD',
                        isChecked: false,
                      },
                      {
                        id: 'address-update-501',
                        label: 'Update',
                        name: 'UPDATE',
                        isChecked: false,
                      },
                      {
                        id: 'address-update-502',
                        label: 'Update',
                        name: 'UPDATE',
                        isChecked: false,
                      },
                      {
                        id: 'address-correction-501',
                        label: 'Correction',
                        name: 'CORRECTION',
                        isChecked: false,
                      },
                      {
                        id: 'address-delete-501',
                        label: 'Delete',
                        name: 'DELETE',
                        isChecked: false,
                      },
                    ],
                  },
                ],
              },
              {
                id: 'emp-view-employment-tab',
                label: 'View Employment Tab',
                name: 'VIEW_EMPLOYMENT_TAB',
                isChecked: false,
                children: [
                  {
                    id: 'emp-view-employment-data-section-01',
                    label: 'View Employment Data Section',
                    name: 'VIEW_EMPLOYMENT_DATA_SECTION',
                    isChecked: false,
                    isExpanded: true,
                    children: [
                      {
                        id: 'emp-employment-data-update-01',
                        label: 'Update',
                        name: 'EMP_EMPLOYMENT_DATA_UPDATE',
                        isChecked: false,
                      },
                      {
                        id: 'emp-employment-data-correction-01',
                        label: 'Correction',
                        name: 'EMP_EMPLOYMENT_DATA_CORRECTION',
                        isChecked: false,
                      },
                      {
                        id: 'emp-employment-data-delete-01',
                        label: 'Delete',
                        name: 'EMP_EMPLOYMENT_DATA_DELETE',
                        isChecked: false,
                      },
                    ],
                  },
                  {
                    id: 'emp-view-work-location-section-01',
                    label: 'View Work Location Section',
                    name: 'VIEW_WORK_LOCATION_SECTION',
                    isChecked: false,
                    isExpanded: true,
                    children: [
                      {
                        id: 'emp-employment-work-location-add-01',
                        label: 'Add',
                        name: 'EMP_EMPLOYMENT_WORK_LOCATION_ADD',
                        isChecked: false,
                      },
                      {
                        id: 'emp-employment-work-location-update-01',
                        label: 'Update',
                        name: 'EMP_EMPLOYMENT_WORK_LOCATION_UPDATE',
                        isChecked: false,
                      },
                      {
                        id: 'emp-employment-work-location-correction-01',
                        label: 'Correction',
                        name: 'EMP_EMPLOYMENT_WORK_LOCATION_CORRECTION',
                        isChecked: false,
                      },
                      {
                        id: 'emp-employment-work-location-delete-01',
                        label: 'Delete',
                        name: 'EMP_EMPLOYMENT_WORK_LOCATION_DELETE',
                        isChecked: false,
                      },
                    ],
                  },
                ],
              },
              {
                id: 'emp-view-cost-center-tab',
                label: 'View Cost Center Tab',
                name: 'VIEW_COST_CENTER',
                isChecked: false,
                isExpanded: true,
                children: [
                  {
                    id: 'emp-view-cost-center-add',
                    label: 'Add',
                    name: 'EMP_VIEW_COST_CENTER_ADD',
                    isChecked: false,
                  },
                  {
                    id: 'emp-view-cost-center-update',
                    label: 'Update',
                    name: 'EMP_VIEW_COST_CENTER_UPDATE',
                    isChecked: false,
                  },
                  {
                    id: 'emp-view-cost-center-correction',
                    label: 'Correction',
                    name: 'EMP_VIEW_COST_CENTER_CORRECTION',
                    isChecked: false,
                  },
                  {
                    id: 'emp-view-cost-center-delete',
                    label: 'Delete',
                    name: 'EMP_VIEW_COST_CENTER_DELETE',
                    isChecked: false,
                  },
                ],
              },
              {
                id: 'emp-view-bank-tab',
                label: 'View Bank Tab',
                name: 'VIEW_BANK',
                isChecked: false,
                isExpanded: true,
                children: [
                  {
                    id: 'emp-view-bank-add',
                    label: 'Add',
                    name: 'EMP_VIEW_BANK_ADD',
                    isChecked: false,
                  },
                  {
                    id: 'emp-view-bank-update',
                    label: 'Update',
                    name: 'EMP_VIEW_BANK_UPDATE',
                    isChecked: false,
                  },
                  {
                    id: 'emp-view-bank-correction',
                    label: 'Correction',
                    name: 'EMP_VIEW_BANK_CORRECTION',
                    isChecked: false,
                  },
                  {
                    id: 'emp-view-bank-delete',
                    label: 'Delete',
                    name: 'EMP_VIEW_BANK_DELETE',
                    isChecked: false,
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
];

export default {
  headerKey,
  locations,
  filterFields,
  defaultFilterValue,
  detailKey,
  stepperCreateUserAccess,
  listVariant,
  stepperStep,
  dataAccessLabel,
  functionAccessLabel,
};
