import { Box, Typography, useTheme } from '@mui/material';
import { useForm } from 'react-hook-form';
import { FileX02, Edit } from '../../../atom/icon';
import { Modal } from '../../../molecule';
import InputWithLabel from '../../../molecule/form/InputWithLabel/InputWithLabel';

type WithdrawProps = {
  open: boolean;
  handleClose?: any;
  handleConfirm?: any;
  control?: any;
  title?: string;
  caption?: string;
  showInputField?: boolean;
};

const WithdrawConfirmation = ({
  open,
  handleClose,
  handleConfirm,
  control,
  title,
  caption,
  showInputField = true,
}: WithdrawProps) => {
  const { palette } = useTheme();

  return (
    <Modal
      fullHeight
      open={open}
      onClose={handleClose}
      showCloseIcon
      title={title || 'Withdraw Termination'}
      leftButtonProps={{
        variant: 'outlined',
        onClick: handleClose,
      }}
      rightButtonProps={{
        variant: 'primary',
        onClick: handleConfirm,
      }}
      leftButtonLabel="Cancel"
      rightButonLabel="Yes, Proceed"
      caption={
        caption || (
          <Typography
            variant="body14"
            color={palette.gray[80]}
            component="p"
            fontWeight="400"
          >
            This withdrawal will make the employee{' '}
            <Typography
              variant="body14"
              color={palette.gray[80]}
              fontWeight="700"
              component="span"
            >
              active
            </Typography>
            . The termination details will be recorded in{' '}
            <Typography
              variant="body14"
              color={palette.gray[80]}
              fontWeight="700"
              component="span"
            >
              Employment History.{' '}
            </Typography>
            Are you sure you want to proceed?
          </Typography>
        )
      }
      icon={
        <Box
          sx={{
            background: palette.blue[20],
            height: '48px',
            width: '48px',
            borderRadius: '90px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            '& svg path': {
              stroke: palette.blue[70],
            },
          }}
        >
          <FileX02 />
        </Box>
      }
    >
      {showInputField && (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
          <InputWithLabel
            control={control}
            name="reason"
            label="Reason"
            placeholder="e.g. to comply with company policies"
            required={false}
          />
        </Box>
      )}
    </Modal>
  );
};

export default WithdrawConfirmation;
