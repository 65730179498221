import { apiSlice } from '../api/apiSlice';

export const AccessControlApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getListAccessControl: builder.query({
      query: params => {
        const { search, page, sortType, sort, searchString } = params;
        return {
          url: '/security/access-setups',
          params: { search, page, sortType, sort, ...searchString },
        };
      },
      providesTags: ['accessControlList'],
    }),
    getDetailAccessControl: builder.query({
      query: ({ id }) => ({
        url: `/security/access-setups/${id}`,
      }),
      providesTags: ['accessControlDetail'],
    }),
    getAccessDatas: builder.query<any, any>({
      query: params => ({
        url: '/security/access-data',
        params,
      }),
    }),
    getAccessFunctions: builder.query<any, any>({
      query: params => ({
        url: '/security/access-functions',
        params,
      }),
    }),
    createAccessControl: builder.mutation({
      query: (params: { body: any }) => {
        const { body } = params;
        return {
          method: 'POST',
          url: '/security/access-setups',
          body,
        };
      },
      invalidatesTags: ['accessControlList'],
    }),
    updateAccessControl: builder.mutation({
      query: (params: { id: any; body: any }) => {
        const { id, body } = params;
        return {
          method: 'PUT',
          url: `/security/access-setups/${id}`,
          params: id,
          body,
        };
      },
      invalidatesTags: ['accessControlDetail'],
    }),
    deleteAccessControl: builder.mutation({
      query: (params: any) => {
        const { id } = params;
        return {
          method: 'DELETE',
          url: '',
          params: id,
        };
      },
      invalidatesTags: ['accessControlDetail', 'accessControlList'],
    }),
  }),
});

export const {
  useGetListAccessControlQuery,
  useGetAccessDatasQuery,
  useGetAccessFunctionsQuery,
  useGetDetailAccessControlQuery,
  useCreateAccessControlMutation,
  useUpdateAccessControlMutation,
  useDeleteAccessControlMutation,
} = AccessControlApiSlice;
