import { RouteNode } from '../../../@types';
import userAccessRoutes from './userAccess';
import AccessControlRoutes from './accessControl';
import EssAccessControl from './EssAccessControl';
import { ShieldTick } from '../../../components/atom/icon';

const security: RouteNode[] = [
  {
    label: 'Security',
    path: 'security',
    icon: ShieldTick,
    component: null,
    child: [
      // ...userAccessRoutes, ...AccessControlRoutes,
      ...EssAccessControl,
    ],
  },
];

export default security;
