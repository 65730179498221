import { createSlice } from '@reduxjs/toolkit';
import { homeApiSlice } from './service/homeApiSlice';

type InitialStateType = {
  messageSuccess: string;
  messageError: string;
};

const initialState: InitialStateType = {
  messageSuccess: '',
  messageError: '',
};

const homeSlice = createSlice({
  name: 'home',
  initialState,
  reducers: {
    removeMessage: state => {
      state.messageSuccess = '';
      state.messageError = '';
    },
  },
  extraReducers(builder) {
    builder.addMatcher(
      homeApiSlice.endpoints.approvalTask.matchFulfilled,
      (state: InitialStateType, action: any) => {
        if (action?.payload?.statusCode === 'APPROVED') {
          state.messageSuccess = 'Time Off Request approved';
        } else {
          state.messageSuccess = 'Time Off Request rejected';
        }
      }
    );
    builder.addMatcher(
      homeApiSlice.endpoints.approvalTask.matchRejected,
      (state: InitialStateType, action: any) => {
        const payload = action.payload as any;
        if (payload.data?.errors?.statusCode?.[0] === 'ACTION-ALREADY-TAKEN') {
          state.messageError =
            'Another approver has taken action on this Time Off Request';
        } else {
          state.messageError = payload.data.message;
        }
      }
    );
    builder.addMatcher(
      homeApiSlice.endpoints.approveRequestUpdateData.matchFulfilled,
      (state: InitialStateType, action: any) => {
        if (
          action?.payload?.status?.value === 'ACCEPT' ||
          action?.payload?.status?.value === 'ACCEPTED' ||
          action?.payload?.status?.value === 'APPROVED'
        ) {
          state.messageSuccess = 'Data Update Request approved.';
        } else {
          state.messageSuccess = 'Data Update Request rejected.';
        }
      }
    );
    builder.addMatcher(
      homeApiSlice.endpoints.approveRequestUpdateData.matchRejected,
      (state: InitialStateType, action: any) => {
        const payload = action.payload as any;
        if (payload.data?.errors?.statusCode?.[0] === 'ACTION-ALREADY-TAKEN') {
          state.messageError =
            'Another approver has taken action on this Data Update Request';
        } else {
          state.messageError = payload.data.message;
        }
      }
    );
  },
});

export const { removeMessage } = homeSlice.actions;
export const homeReducer = homeSlice.reducer;
