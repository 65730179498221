// import { Typography, List, ListItemButton } from '@mui/material';
import { Collapse, List } from '@mui/material';
import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { NavMenu } from '../../atom';
import { RouteProps, RouteNode } from '../../../@types/RouteProps';
import { useAppDispatch } from '../../../store';
import { closeSideBar } from '../../../store/feature/responsiveSlice';

const TreeList: React.FC<RouteProps> = ({ data, sx }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [expanded, setExpanded] = useState<string[]>(
    location.pathname.split('/')
  );
  const dispatch = useAppDispatch();
  const handleClick = (path: string, isParent: boolean, goto: string) => {
    if (!isParent) {
      // navigate(goto === '/' ? '/' : `/${goto}`);
      setExpanded(goto.split('/'));
      dispatch(closeSideBar());
    } else if (expanded.includes(path)) {
      const ex = expanded.filter(item => item !== path);
      setExpanded(ex);
    } else {
      // doing this so when user click non navigate dropdown, other open dropdown not automaticly closed
      setExpanded([...goto.split('/'), ...expanded]);
    }
  };

  const check = (node: any) => {
    const isExist = location.pathname
      .split('/')
      .slice(1, 4)
      .join('/')
      .includes(node.path === '/' ? '--|--|--|--' : node.path);

    const indexOfString = location.pathname
      .split('/')
      .slice(1, 4)
      .indexOf(node.path === '/' ? '--|--|--|--' : node.path);

    const isTheSameLength =
      node.path.length ===
      location.pathname.split('/')[indexOfString + 1]?.length;

    return isExist && isTheSameLength;
  };

  const renderList = (nodes: RouteNode[], num: number, path: string[]) => (
    <List
      component="nav"
      sx={{
        padding: 0,
        display: 'flex',
        flexDirection: 'column',
        gap: '4px',
        ...sx,
      }}
    >
      {nodes
        .filter(node => node.label !== 'child')
        .map(node => {
          const { icon: ComponentIcon } = node;
          return (
            <React.Fragment key={node.path}>
              {node.label && (
                <NavMenu
                  onClick={() =>
                    handleClick(
                      node.path,
                      node.child !== null,
                      `${[...path, node.path].join('/')}`
                    )
                  }
                  path={`${[...path, node.path].join('/')}`}
                  label={node.label}
                  isActive={
                    node.path === '/' && location.pathname === '/'
                      ? true
                      : check(node)
                  }
                  depth={num}
                  icon={ComponentIcon ? <ComponentIcon /> : null}
                  isParent={node.child !== null}
                  isExpended={expanded.includes(node.path)}
                />
              )}
              {node.child && (
                <Collapse
                  in={expanded.includes(node.path)}
                  timeout="auto"
                  unmountOnExit
                  // onExited={() => {}}
                >
                  {renderList(node.child, num + 1, [...path, node.path])}
                </Collapse>
              )}
            </React.Fragment>
          );
        })}
    </List>
  );
  return renderList(data, 0, []);
};

export default TreeList;
