import { formatSecondsToTimeString } from '../utils/date';

export const tableListHeaderKey = {
  name: 'Name',
  code: 'Code',
  timeItemName: 'Time Item',
  typeName: 'Type',
  overnightShift: 'Overnight Shift',
  startTime: 'Start Time',
  endTime: 'End Time',
  totalWorkHours: 'Total Work Hour(s)',
  colorIndicator: 'Color Indicator',
};

export const formDefaultValue = {
  name: '',
  code: '',
  timeItem: null,
  overnightShift: false,
  startTime: '',
  endTime: '',
  requiredStartWorkHours: '',
  requiredWorkMinute: '',
  typeShift: 'FIXED',
  totalWorkHours: formatSecondsToTimeString(0),
  colorIndicator: '#FFBB33',
  effectiveDate: null,
};

export const colorsOption = [
  '#F2F2F2',
  '#FFF6E5',
  '#F6FFE5',
  '#E5FFE5',
  '#E5FFF6',
  '#E5F6FF',
  '#E5E5FF',
  '#F6E6FF',
  '#FFE5F6',
  '#FFE5E5',
  '#E6E6E6',
  '#FFEECC',
  '#EEFFCC',
  '#CCFFCC',
  '#CCFFED',
  '#B2E5FF',
  '#B2B2FF',
  '#E5B2FF',
  '#FFB2E5',
  '#FFB3B3',
  '#CCCCCC',
  '#FFDD99',
  '#DDFF99',
  '#99FF99',
  '#99FFDC',
  '#80D3FF',
  '#8080FF',
  '#D480FF',
  '#FF80D3',
  '#FF8080',
  '#B3B3B3',
  '#FFCC66',
  '#CCFF66',
  '#66FF66',
  '#66FFCA',
  '#4DC1FF',
  '#6666FF',
  '#CC66FF',
  '#FF66CA',
  '#FF6666',
  '#999999',
  '#FFBB33',
  '#BBFF33',
  '#33FF33',
  '#00FFA7',
  '#1AB0FF',
  '#5C33FF',
  '#AA00FF',
  '#FF33B8',
  '#FF3333',
];

export const typeShiftDictionary: any = {
  FIXED: 'Fixed',
  FLEXIBLE_WITH_TIME_RANGE: 'Flexible With Time Range',
  FLEXIBLE: 'Flexible',
};
