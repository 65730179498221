import { useState } from 'react';
import { Box, CircularProgress, Grid } from '@mui/material';
import { useParams } from 'react-router-dom';
import { ESSContainer } from '../../components/template';
import { objectMapper } from '../../utils/objectMap';
import LabelTextForHtml from '../../components/atom/labelText/LabelTextForHtml';
import { useShiftDetailQuery } from '../../store/feature/service/shiftApiSlice';
import { ActionMenuProps } from '../../@types/TableProps';
import { useAppDispatch, useAppSelector } from '../../store';
import Toast from '../../components/atom/toast/Toast';
import { removeMessage } from '../../store/feature/shiftSlice';
import { FileX02 } from '../../components/atom/icon';
import { useDocumentTitle } from '../../hooks/useDocumentTitle';
import HistoricalTimeLine from '../../components/molecule/historicalTimeLine/HistoricalTimeLine';
import {
  formatSecondsToHour,
  formatSecondsToTimeString,
} from '../../utils/date';
import Breadcrumb from '../../components/molecule/breadcrumb/Breadcrumb';
import { Title } from '../../components/molecule';
import ActionButton from '../../components/molecule/action/Action';
import WithdrawConfirmation from '../../components/organism/modal/WithdrawConfirmation/WithdrawConfirmation';
import { useDisclosure } from '../../hooks/useDisclosure';

const DetailApprovedTimeOff = () => {
  const [openWithdraw, { toggle }] = useDisclosure();
  const params = useParams<{ id: string }>();
  const { messageSuccess, messageError } = useAppSelector(state => state.shift);
  const [selectedDate, setSelectedDate] = useState('');
  const handlePreviousDate = () => {
    setSelectedDate?.(data?.previousEffectiveDate || '');
  };
  const handleUpcomingDate = () => {
    setSelectedDate?.(data?.upcomingEffectiveDate || '');
  };
  const { data, isLoading } = useShiftDetailQuery({
    effectiveDate: selectedDate,
    id: params?.id || '',
  });

  useDocumentTitle(
    isLoading ? 'TricorUnify' : `${data?.data?.old?.name} | TricorUnify`
  );

  const actionMenu: ActionMenuProps[] = [
    {
      name: 'withdraw',
      title: 'Withdraw',
      Icon: <FileX02 />,
    },
  ];

  const handleClickAction = (action: string) => {
    if (action === 'withdraw') {
      toggle();
    }
  };

  const detailHeaderKey: any = {
    effectiveDate: 'Effective Date',
    name: 'Name',
    code: 'Code',
    timeItem: 'Time Item',
    typeShift: 'Type',
    overNightShift: 'Overnight Shift',
    startTime: 'Start Time',
    endTime: 'End Time',
    totalWorkHours: 'Total Work Hour(s)',
    requiredWorkHour: 'Required Work Hour(s)',
    colorIndicator: 'Color Indicator',
  };

  const currentData: { [key: string]: any } = {
    ...objectMapper(data?.data?.old || {}),
    startTime: formatSecondsToHour(data?.data?.old?.startTime),
    endTime: formatSecondsToHour(data?.data?.old?.endTime),
    totalWorkHours: data?.data?.old?.totalWorkHours
      ? formatSecondsToTimeString(data?.data?.old?.totalWorkHours)
      : '-',
    requiredWorkHour: data?.data?.old?.requiredWorkHour
      ? formatSecondsToTimeString(data?.data?.old?.requiredWorkHour)
      : '-',
    effectiveDate: (
      <HistoricalTimeLine
        headersState={data}
        handleClickPrevious={handlePreviousDate}
        handleClickUpcoming={handleUpcomingDate}
      />
    ),
    colorIndicator: (
      <Box sx={{ display: 'flex', gap: '8px' }}>
        <Box
          sx={{
            width: '20px',
            height: '20px',
            bgcolor: data?.data?.old?.colorIndicator,
            borderRadius: '4px',
          }}
        />
        {data?.data?.old?.colorIndicator?.split('#')[1]}
      </Box>
    ),
  };

  const upcomingData: { [key: string]: any } = {
    ...objectMapper(data?.data?.new || {}),
    startTime: formatSecondsToHour(data?.data?.new?.startTime),
    endTime: formatSecondsToHour(data?.data?.new?.endTime),
    totalWorkHours: data?.data?.new?.totalWorkHours
      ? formatSecondsToTimeString(data?.data?.new?.totalWorkHours)
      : '-',
    requiredWorkHour: data?.data?.new?.requiredWorkHour
      ? formatSecondsToTimeString(data?.data?.new?.requiredWorkHour)
      : '-',
    colorIndicator: (
      <Box sx={{ display: 'flex', gap: '8px' }}>
        <Box
          sx={{
            width: '20px',
            height: '20px',
            bgcolor: data?.data?.new?.colorIndicator,
            borderRadius: '4px',
          }}
        />
        {data?.data?.new?.colorIndicator?.split('#')[1]}
      </Box>
    ),
  };

  const dispatch = useAppDispatch();

  return (
    <ESSContainer>
      <WithdrawConfirmation
        open={openWithdraw}
        title="Withdraw Time Off"
        caption="Are you sure want to withdraw this time off request?."
        showInputField={false}
        handleClose={() => toggle()}
        handleConfirm={() => console.log('Cancelled')}
      />
      <Breadcrumb className="breadcrumb" />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Title title={data?.data?.old?.name} />
        <ActionButton
          actions={actionMenu}
          onClickMenuAction={handleClickAction}
        />
      </Box>
      <Toast
        variant="success"
        open={messageSuccess !== ''}
        message={messageSuccess}
        onClose={() => dispatch(removeMessage())}
      />
      <Toast
        variant="error"
        open={messageError !== ''}
        message={messageError}
        onClose={() => dispatch(removeMessage())}
      />
      {isLoading ? (
        <CircularProgress />
      ) : (
        <Grid container rowSpacing={1}>
          {Object.keys(detailHeaderKey)?.map((key: any) => (
            <Grid item xs={7} key={key}>
              <LabelTextForHtml
                headerKey={detailHeaderKey?.[key]}
                currentData={currentData?.[key]}
                upcommingData={upcomingData?.[key]}
                dataPresentationStatus={data?.dataPresentationStatus}
              />
            </Grid>
          ))}
        </Grid>
      )}
    </ESSContainer>
  );
};

export default DetailApprovedTimeOff;
